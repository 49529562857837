import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import LinkGrid from "../../components/terms/link-grid";
import PolicyDetails from "../../components/terms/professional-services-agreement";
import ProfessionalAgreementBanner from "../../components/terms/professional-agreement-banner";

const ProfessionalServicesAgreement = () => {
	return (
		<Layout>
			<SEO
				title='Sitey Professional Service Agreement'
				pageType='terms'
				flowType='none'
			/>
			{typeof window !== "undefined" && (
				<LinkGrid pathname={location.pathname} />
			)}
			<PolicyDetails />
			{/* TODO: update the display settings for
          when the banner component is not displayed
          it occupies its position
          - Now the banner isn't displayed
      */}
			<ProfessionalAgreementBanner />
		</Layout>
	);
};

export default ProfessionalServicesAgreement;
